/* =====================================
   BASE STYLES
======================================== */

main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "TCCC-UnityText-Regular", Helvetica, sans-serif;
  }
  
  .responsive {
    width: 100%;
    height: auto;
  }
  .eq-logo {
    max-width: 160px;
  }
  
  /* .prim-color {
    color: #007B17;
  } */
  
  .vid-container {
    max-width: 75%;
    margin: auto;
  }
  
  .border-end {
    border-right: 0px solid #fff !important;
  }
  
  /* NAV       */
  
  .nav-hover.active,
  .nav-hover:hover {
    background-color: #00ec2c;
  }
  
  /* .nav-bg-color {
    background-color: #00ec2c;
    color: "white";
  } */
  
  .border-end {
    border-right: 4px !important;
  }
  
  /*  STICKY FOOTER    */
  
  .main {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  
  .flex-sf {
    flex: 1;
  }
  
  /* Breakout Directory Colors */
  .color-b-1 {
    background-color: #f40b07;
  }
  .color-b-2 {
    background-color: #f52908;
  }
  .color-b-3 {
    background-color: #f54804;
  }
  .color-b-4 {
    background-color: #f66d03;
  }
  .color-b-5 {
    background-color: #f88a01;
  }
  .color-b-6 {
    background-color: #ed9d10;
  }
  .color-b-7 {
    background-color: #ed9d10;
  }
  .color-b-8 {
    background-color: #c7ad60;
  }
  .color-b-9 {
    background-color: #b6b160;
  }
  .color-b-10,
  .color-b-11,
  .color-b-12,
  .color-b-13,
  .color-b-14 {
    background-color: #64b97e;
  }
  
  .color-button:hover {
    background-color: #eb9234;
  }
  
  .btn-dark,
  .btn-dark:hover {
    border-color: #fff;
  }
  
  .btn-logout {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  
  /* Login Styles */

  .auth-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    height: 100vh;
    width: 100vw;
    /* object-fit: ÷; */
    object-position: center center;
  }
  
  .auth-form {
    position: relative;
    max-width: 600px;
    width: 90vw;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 1rem;
    border-radius: 30px;
    /* color: white; */
  }
  
  .bg-red {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  /* .link-primary {
    color: #00ec2c;
  }
  
  .link-primary:hover,
  .link-primary:focus {
    color: #007B17;
  } */
  
  /* =====================================
     TECH SUPPORT
  ======================================== */
  
  /*  TECH SUPPORT ACCORDION  */
  
  .tech-accordion {
    background-color: #00ec2c;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    text-align: center;
    outline: none;
    transition: 0.4s;
  }
  .fs-5.mb-1 {
    font-weight: bold;
  }
  .bo-iframe {
    padding: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 0.1rem;
    text-align: center;
  }
  .tech-active,
  .tech-accordion:hover {
    color: white;
    background-color: #00ec2c;
  }
  
  .panel {
    background-color: #fff;
    color: #000;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    margin: 0 auto;
  }
  
  .menu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin: 20px auto;
    background: transparent;
    font-weight: 700;
  }
  
  .menu a {
    text-decoration: none;
  }
  
  .tech-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    color: #fff;
    /* background-color: #00ec2c;
    border-bottom: 4px solid #007B17; */
  }
  
  .tech-btns:hover {
    color: #fff;
    background-color: #707070;
  }
  
  .target_box {
    width: 95%;
    margin: 0 auto;
    background: transparent;
    padding-bottom: 60px;
  }
  
  .image {
    width: 100%;
    margin: 0 auto;
  }
  
  .tech-section-indent {
    margin-left: 40px;
  }
  
  #meet {
    /* background-image: url(./assets/images/logo.png); */
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  /* =====================================
     MEDIA QUERIES
  ======================================== */
  
  @media (min-width: 768px) {
    .auth-form {
      padding: 3rem;
    }

    .hd-logo {
      max-width: 305px;
    }
  
    /*  TECH SUPPORT    */
  
    .menu {
      width: 100%;
    }
  
    .tech-btns {
      width: 15%;
      flex-wrap: nowrap;
      margin: 0 2%;
    }
  
    .target_box {
      width: 80%;
    }
  
    .image {
      width: 60%;
    }
    /* NAV       */
    .border-end {
      border-right: 2px solid #fff !important;
    }
  }
  /* ===================================== 
     BASE STYLES
  ======================================== */
  
  .eq-logo {
    max-width: 160px;
  }
  
  /* .prim-bg {
    background-image: url("../images/equifax-hd-gradient.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  } */
  
  /* .prim-color {
    color: #00ec2c;
  } */
  
  .vid-container {
    max-width: 75%;
    margin: auto;
  }
  
  .nav-bg-color2:hover {
    background-color: #7c1528;
  }

  .nav-bg-color2 {
    color: white !important;
    background-color: gray;
  }
  
  .border-end {
    border-right: 0px solid #fff !important;
  }
  
  
  /* ===================================== 
     TECH SUPPORT
  ======================================== */
  
  /*  TECH SUPPORT ACCORDION  */
  
  .tech-accordion {
    background-color: #5B6771;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    text-align: center;
    outline: none;
    transition: 0.4s;
  }
  
  .tech-active, .tech-accordion:hover {
    background-color: #9E1B32;
  }
  
  .panel {
    background-color: #fff;
    color: #000;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    margin: 0 auto;
  }
  
  /*      SUPPORT     */
  
  .menu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin: 20px auto;
    background: transparent;
  }
  
  .menu a {
    text-decoration: none;
  }
  
  .tech-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    color: #fff;
    background-color: #5B6771;
    border-bottom: 4px solid #9E1B32;
  }
  
  .tech-btns:hover {
    color: #fff;
    border-bottom: 4px solid #f46628;
  }
  
  .target_box {
    width: 95%;
    margin: 0 auto;
    background: transparent;
    padding-bottom: 60px;
  }
  
  .image {
    width: 100%;
    margin: 0 auto;
  }
  
  .tech-section-indent {
    margin-left: 40px;
  }
  
  .spkr-img {
    display: block;
    max-width: 250px;
    margin: auto;
    margin-bottom: 20px;
  }
  
  
  /* ===================================== 
     MEDIA QUERIES
  ======================================== */
  
  @media (min-width: 768px) {
  
    .eq-logo {
        max-width: 210px;
    }
  
    /*  TECH SUPPORT    */
    
    .menu {
        width: 100%;
    }
    
    .tech-btns {
        width: 15%;
        flex-wrap: nowrap;
        margin: 0 2%;
    }
    
    .target_box {
        width: 80%;
    }
    
    .image {
        width: 60%;
    }
  
    .border-end {
        border-right: 2px solid #fff !important;
    }
  
  }
  
  
  
  /* SPEAKERS */
  
  .spkr-img {
    max-width: 250px;
    display: block;
    margin: auto;
  }
  /* ===================================== 
     BASE STYLES
  ======================================== */
  
  /* font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-family: 'Sen', sans-serif; */
  
  /*  Header  */
  
  .logo {
    display: block;
    margin: 0 auto;
    max-width: 250px;
    padding: 1rem;
  }
  
  .event-logo {
    max-width: 200px;
  }
  
  .event-logo-2 {
    max-width: 100px;
  }
  
  .event-logo-ft {
    max-width: 200px;
  }
  
  .event-logo-ft-2 {
    max-width: 100px;
  }
  
  /* .primary-color {
    color: #007b17;
  }
  
  .secondary-color {
    color: #00ec2c;
  } */
  
  .bg-color-primary {
    background-color: #fff;
  }
  
  /*  Nav */
  
  /* .nav-bg-color {
    background-color: #007b17;
  } */
  
  
  /*  STICKY FOOTER    */
  
  .body-sf {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  
  .flex-sf {
    flex: 1;
  }
  
  /* SPEAKERS */
  
  .spkr-img {
    max-width: 250px;
    display: block;
    margin: auto;
  }
  
  
  /* ===================================== 
     MEDIA QUERIES
  ======================================== */
  
  @media (min-width: 768px) {
  
    .event-logo {
        max-width: 300px;
    }
    
    .event-logo-2 {
        max-width: 150px;
    }
  
    /* NAV       */
    .border-end {
        border-right: 2px solid #fff !important;
    }
  
  }
  
  .nav-color {
    /* color: "#F7980C" !important; */
    background-color: "#007B17" !important;
  }