/* ===================================== 
   BASE STYLES
======================================== */

/* font-family: 'Anton', sans-serif;
font-family: 'Bebas Neue', cursive;
font-family: 'Sen', sans-serif; */

/*  Header  */

.logo {
  display: block;
  margin: 0 auto;
  max-width: 250px;
  padding: 1rem;
}

.event-logo {
  max-width: 200px;
}

.event-logo-2 {
  max-width: 100px;
}

.event-logo-ft {
  max-width: 200px;
}

.event-logo-ft-2 {
  max-width: 100px;
}

.primary-color {
  color: #037bb6;
}

.secondary-color {
  color: #faa21b;
}

.bg-color-primary {
  background-color: #fff;
}

/*      ACCORDION    */

.accordion-text {
  background-color: #fff;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  padding: 0;
}

/* .active, .accordion-text:hover {
  background-color: #ccc;
} */

.slide {
  padding-top: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

/* ===================================== 
   TECH SUPPORT
======================================== */

/*  TECH SUPPORT ACCORDION  */

.tech-accordion {
  /* background-color: #037bb6; */
  color: #fff;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  transition: 0.4s;
}
.tech-accordion1 {
  /* background-color: #037bb6; */
  color: #fff;
  background-color: #007ec1;
  cursor: pointer;
  padding: 3px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  transition: 0.4s;
  font-size:  smaller;
}

.tech-active, .tech-accordion:hover {
  background-color: #707070;
}

.panel {
  background-color: #fff;
  color: #000;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  margin: 0 auto;
}

/*      SUPPORT     */

.menu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin: 20px auto;
  background: transparent;
}

.menu a {
  text-decoration: none;
}

.tech-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  color: #fff;
  background-color: #037bb6;
  border-bottom: 4px solid #faa21b;
}

.tech-btns:hover {
  color: #fff;
  background-color: #707070;
}

.target_box {
  width: 95%;
  margin: 0 auto;
  background: transparent;
  padding-bottom: 60px;
}

.image {
  width: 100%;
  margin: 0 auto;
}

.tech-section-indent {
  margin-left: 40px;
}

/*  STICKY FOOTER    */

.body-sf {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.flex-sf {
  flex: 1;
}

/* SPEAKERS */

.spkr-img {
  max-width: 250px;
  display: block;
  margin: auto;
}

.bio-img {
  max-width: 350px;
  display: block;
  margin: auto;
}


/* ===================================== 
   MEDIA QUERIES
======================================== */

@media (min-width: 768px) {

  .event-logo {
      max-width: 300px;
  }
  
  .event-logo-2 {
      max-width: 150px;
  }

  .logo {
      max-width: 300px;
  }

  /* NAV       */
  .border-end {
      border-right: 2px solid #fff !important;
  }

  /*  TECH SUPPORT    */

  .menu {
      width: 100%;
  }
  
  .tech-btns {
      width: 15%;
      flex-wrap: nowrap;
      margin: 0 2%;
  }
  
  .target_box {
      width: 80%;
  }
  
  .image {
      width: 60%;
  }

}


